@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Nunito:wght@200;300;400;600;700;800&display=swap');
// &family=Quicksand:wght@300;400;600
@import './variables.scss';
@import './mixins.scss';

html{
  p,h4,h3,h2, li{color:$gray;}
  p,h4,h3,h2,h1, li{font-family:$body;}
  p, li{font-size:18px;}
  a:hover, button:hover{text-decoration: none; opacity: .75; @include ease(.3s);}
}

.inside, .insideAlt, .medDef, .med2Def, .med3Def, .insideXl, .insideN, .insideNrw{ @include center-block(); max-width:1700px;}
.insideN{width:95%;}
.inside{width: 85%;}
.insideXl {width: 90%;}
.insideAlt {width: 80%;}
.medDef {width:1080px;}
.med2Def{width: 940px;}
.med3Def{width: 850px;}

@media (max-width:1235px) {
  .medDef {width:90%;}
}

@include bp(br) {
  .med2Def, .med3Def {width:85%;}
}

@media screen and (min-width:823px){
  .insideNrw{width:820px;}
}

@include bp(tn) {
  .insideNrw{width:90%}
}

.fullRow{@include sec-default();}
.centerBlock{ @include center-block();}
.bmargin0 {margin-bottom: 0;}
.tmargin0 {margin-top: 0;}
.textCenter {text-align: center;}
.tpadding4{padding-top:40px;}
.tpadding6{padding-top:60px;}

.spacer1 {@include sec-default();height:10px;}
.spacer2 {@include sec-default();height:20px;}
.spacer25 {@include sec-default();height:25px;}
.spacer3 {@include sec-default();height:30px;}

hr{border-color: rgba($black,.1);
	&.hlf {width: 50%;}
	&.less {width: 25%;}
}
.clearfix::after, .clearfixBtn::after {
  content: "";
  clear: both;
  display: table;
}
.clearfix.btn, .clearfixBtn {@include sec-default(); margin-top:20px;}
.clearBorder, .clearBorderWhite, .clearBorderLeft{@include sec-default(); height:50px; position: relative; z-index: 0; margin:0px 0 20px;
  &::before{@include before(); width:1px; left:49.7%; background-color: rgba($black,.2);}
}
.clearBorderWhite::before{background-color:rgba($white, .3);}
.clearBorderLeft::before{left:12%;}

.cfLink,.cfLinkRound, .cfLinkAlt {@include link($red, $white); padding: 10px 20px;  box-shadow:0px 5px 15px rgba($black,.3); 
  letter-spacing: 1px; text-transform: uppercase;
  &:hover{background-color:$white; color:$red;}
 
  @include bp(tb) {font-size:15px;}
  @include bp(br) {font-size:14px;}
}
.cfLinkAlt{background-color:$white; color:$red;
  &:hover{background-color:$red; color:$white;}
}

.textLink{font-weight: bold;
  &.und {text-decoration: underline;}
}

.visuallyHidden {@include visuallyHidden();}
.imgResponsive {max-width: 100%; height: auto; display:block;}
ul.disc li{list-style-type: disc;}

