@import "./assets/global";
@import "./assets/variables";
@import "./assets/mixins";

.cfWrap{@include sec-default();background-color:$lcream;}

.ukmCuts{width:75%; @include sec-bgimage('./images/cuts-bg.jpg'); background-position: left center; background-size: cover;
  .textBox{width:50%; float:right; margin-right:1%; text-align: center;}
  p, h4, li{color:$white;}
  h4{font-weight: bold; font-size:1.32rem;
    span{font-size:1.1rem; font-weight: normal;}
  }
  li{list-style-type:none; margin-bottom:10px; font-weight: 500;}
  p{font-size: 16px; line-height: 1.6em;}

  @include bp(tw) {width:85%;}
  @include bp(br) {background-image:url('./assets/images/cuts-bgMobile.jpg'); background-position: left bottom;
    .textBox{width:70%;}
    h4{font-size:1.22rem;
      span{font-size:1rem;}
    }
    li{font-size:17px;}
  }

  @include bp(mb) {
    &::before{@include before(); background-color:rgba($black,.65);}
    .textBox{@include sec-default();}
    h4{font-size:1.18rem;
      span{font-size:1rem;}
    }
    li{font-size:16px;}
    p{font-size:15px;}
  }

  @include bp(sm) {width:95%;}
}

.HomeAlt{@include sec-default(); position:relative;z-index: 0;padding-bottom:115px;
  &::before{@include before();height:700px;background-color:lighten($tan, 2%);}//:#F0F6F4
  &::after{@include after();left:0; height:250px;background-image: url('./assets/images/footer.jpg');background-position:bottom center;}

  @include bp(tb) {&::before{height:500px;}}

  @include bp(md) {
    &::before{display:none;}
    .inside.genInside{width:95%;}
  }
  @include bp(sm) {
    .inside.genInside{width:100%;}
  }
}

.Header{@include sec-default(); text-align:center; padding:7px 0; position:relative;
  .hdLogo, .topItem{display:inline-block; width:30%;}
  .logoImg{width:180px; @include center-block();}
  .topItem {margin-top:60px;
    a{color: $black; font-weight:500;font-size:16px;}
    a:hover{opacity: .8 !important;}
  }
  .tiPhone{float:left;text-align: left;}
  .tiEmail{float:right;text-align:right;}
  
  @include bp(tb) {
    .medDef{width:84%;}
  }

  @include bp(br) {
    .medDef{width:90%;}
    .topItem a{font-size:15px;}
  }

  @media (max-width:767px) {
    .medDef{width:95%;}
    .tiPhone, .tiEmail,.hdLogo{@include sec-default(); text-align: center; margin:5px 0}
    // .hdLogo{@include sec-default(); margin-top:7px;}

  }
}

.bgBlock{@include sec-default(); text-align: center; position:relative; z-index: 0;min-height:750px;
  background-image: url('./assets/images/steaks-plate.jpg');background-position:bottom center; background-size:cover;
  position: relative;z-index:0;&::before{@include before();background:rgba($black,.25);}
  .textBox{position: relative;z-index:0;width:700px;@include center-block(); margin-top:200px;
    &::before{@include before();background:radial-gradient(rgba($black,0.5) 20%, transparent 60%); top:-30px;}
  }
  h1, h2, h3{color:$white; text-shadow:0px 1px 2px rgba($black,.8);text-transform: capitalize; font-family: $mont;}
  // h3{margin:0; font-weight:800;letter-spacing: 1px; text-transform: uppercase;}
  // h1{margin:0 0 10px;font-weight: bold;}
  // h2{margin-top: 0;font-weight: 600;}

  h1{font-size:1.5rem;margin:0; font-weight:800;letter-spacing: 1px; text-transform: uppercase; line-height: 1.4em;}
  h2{margin:0 0 10px;font-size:2.625rem; line-height: 1.2em; font-weight:bold;}
  h3{margin-top: 0;font-weight: 600;font-size:2rem; line-height: 1.3em;}

  
  .cfLink, .cfLinkAlt{display:inline-block; margin:0 10px;
    &:hover{opacity: .8 !important; background-color:$red;}
  }
  .cfLink{border-color:$red;
    &:hover{color:$red; background-color:$white}
  }

  @include bp(tw){min-height:600px;
    h2{font-size:2.3rem;}
    h3{font-size: 1.85rem;}
  }

  @include bp(tb){min-height:500px;
    h2{font-size:2rem;}
    h3{font-size: 1.5rem;}

    .tiPhone{left:2.5%;}
    .tiEmail{right:2.5%;}
    .textBox{width:80%; margin-top:150px;}
  }

  @include bp(br){min-height:450px;
    &::before{background:rgba($black,.35);}
    h1{font-size:1.35rem; font-weight:600;}
    h2{font-size:1.8rem;}
    h3{font-size: 1.25rem;}
    
    .hdLogo{width:200px;}
    .topItem{font-size:15px;}
    .tiPhone{left:2.5%;}
    .tiEmail{right:2.5%;}
  }

  @include bp(md){
    h1{font-size:1.3rem;}
    .textBox{margin-top:120px;}
  }

  @include bp(ph){min-height:350px;
    h1{font-size:1.2rem;}
    h2{font-size:1.65rem;}
    h3{font-size: 1.15rem;}
    .textBox{margin-top:100px;width:95%;}
  }

  @include bp(xs){min-height:300px;
    h1{font-size:1rem;}
    h2{font-size:1.5rem;}
    h3{font-size: 1.1rem;}

    .textBox{margin-top:50px; width:98%;}
    .clearfixBtn{margin-top:0px;}
    .cfLink, .cfLinkAlt{width:200px; max-width: 200px; @include center-block(); padding:10px 0;}
    .cfLink{margin-bottom: 15px;}
  }

  @include bp(xx){
    h2{font-size: 1.3rem;}
  }
}

.cfBody{@include sec-default();padding:100px 0 60px;
  @include bp(tw){padding:60px 0 30px;}
  @include bp(tb){padding:40px 0 0px;}
  @include bp(br){padding:25px 0 0px;}
  @include bp(oc){padding:20px 0 0px;}
}

.cfIntro{@include sec-default(); margin-bottom:35px;
  .cfiHd, .cfContact1, .cfContact2{display:inline-block;}
  .cfiHd{width:40%;margin-left:-3%; margin-top:30px;}
  .cfContact1,.cfContact2{width:28%;}
  .cfContact1{margin-left:7%;}
  .cfContact2{float:right; margin-right: -2%;}

  .cfiHd{float:left;text-align: right;
    h3{font-weight:bold;line-height: 1.7em; margin:0;}
    p{margin:0 0 0px;letter-spacing: 1px; text-transform: uppercase;font-weight:bold;color:$red;}
  }
  .cfContact1,.cfContact2{
    .cfcItem{margin-bottom:20px;}
    p, p a{font-size: 18px;margin-bottom:5px; color:$gray;margin-top:0;}
    p span{font-size:14px; text-transform: uppercase; font-weight: 800; line-height: 1em; letter-spacing: 1px;}
    .textLink{color:$red;}
    .social{color:$gray; margin-left: 5px;}
    svg{width:17px; height:17px;}
    a:hover{opacity: .7 !important;}
  }
  .cfLink{font-size:14px;}

  @include bp(tw){
    .cfContact2{margin-right:0;}
    .cfiHd{margin-left:0;width:35%;
      h3{font-size:1.35rem;}
    }
  }

  @include bp(tb){margin-bottom:20px;
    .cfiHd{width:38%; margin-left:-4%; margin-right:6%;margin-top:40px;
      h3{font-size:1.2rem;}
      p{font-size: 15px;}
    }

    .cfContact1,.cfContact2{margin:0; width:30%;
      p,p a{font-size:17px;}
    }
    .cfContact2{margin-right:-2%;}
  }

  @include bp(br){text-align: center;margin-bottom:0px;
    .cfiHd{@include sec-default(); text-align: center; margin:0 0 15px;
      h3{font-size:1.2rem;}
      p{font-size: 15px;}
    }
    .cfContact2,.cfContact1{float:none; width:auto;margin:0 3%;}
  }

  @include bp(oc) {margin-bottom: 5px;
    .cfiHd{margin-bottom:15px;
      h3{font-size: 1.1rem; line-height: 1.5em;}
    }
    .cfContact2,.cfContact1{@include sec-default();margin:0;
      .cfcItem{margin-bottom: 10px;}
    }
  }

  @include bp(xx){
    .cfContact2,.cfContact1{
      p{font-size:16px;}
    }
  }
}

.cfLinkSec{@include sec-default();position:relative; z-index: 0;text-align:center;padding:40px 0 30px;
  &::before{@include after(); height:60%; right:0;box-shadow: 0 -1px 30px rgba($black,.1);}
  &::after{@include after();height:60%; background-color:$tan;z-index:-2; width:120%; right:-8.6%;}

  .cfItemImage{display:inline-block; margin:0 .5%;width:45%;}
  .cfItem{margin-top: 70px;padding:0 40px; 
    p,a{font-size: 20px;line-height: 1.8em;letter-spacing: .5px;}
    span{font-weight:bold;}
    b{font-weight: 600;}
  }

  .fbPlug{@include sec-default();}
  .img2{position: relative;
    img{position: relative; z-index: 0;}
  }

  .priceLinkTop{position: absolute; z-index:1; width:100%; height:100%;left:0;
    .priceBtn{width:300px; margin-top:20%;  background-color:$white; padding:10px 0; box-shadow:0px 5px 25px rgba($black,.5);
      font-size:22px;font-weight:bold; color:$red;
      text-transform: uppercase; letter-spacing: 1px; border:2px solid $white;

      &:hover{background-color:$red; color:$white; border-color:$red;}
    }
  }

  @media (max-width:1450px) {
    .insideAlt{width:90%;}
  }

  @include bp(tw){padding:50px 0;
    .insideAlt{width:95%;}
    .cfItem{margin-top: 60px;}
    .priceLinkTop .priceBtn{width:275px;font-size:20px;}
  }

  @include bp(tb){padding:0px 0 15px;
    .insideAlt{width:98%;}
    .cfItem{margin-top: 40px;}
    .priceLinkTop .priceBtn{width:250px;font-size:18px;padding:5px 0;}
  }

  @include bp(br){padding:10px 0 10px;
    .insideAlt{width:95%;}
    .cfItem{margin-top: 20px;
      p{font-size:18px;}
    }
  }

  @include bp(md) {padding:10px 0 0px;
    &::before{height:60%;}
    &::after{height:60%;width:110%; right:-2.5%;}
    .insideAlt{width:100%;}
    .cfItem{margin: 15px 0; padding:0;
      p{font-size:17px; line-height: 1.5em;}
    }
    .priceLinkTop .priceBtn{width:210px;font-size:17px;padding:3px 0;}
  }

  @include bp(oc){padding:0 0 0px;
    .priceLinkTop .priceBtn{width:200px;font-size:16px;padding:0px;}
  }

  @include bp(sm){
    &::before{display: none;}
    &::after{width:100%; right:0;}
    .priceLinkTop .priceBtn{margin-top:15%;width:200px;font-size:16px; line-height:1em;padding:7px 0px;}
  }
}

.cfHistory{@include sec-default();padding:80px 0 100px; background-color:$lcream; 
  .hlf1, .hlf2 {display: inline-block; width:48%;
    .textBox{width:90%; @include center-block(); margin-top:60px;}
    .mobileOnly{display: none;}
  }
  .hlf1{float:right;
    .textBox{width:85%;margin:60px auto 80px;}
  }
  h3{font-weight: bold; text-transform: uppercase; letter-spacing: 1px;}

  @media (max-width:1450px) {
    .inside{width:90%;}
    .hlf1{
      .textBox{width:90%;margin:40px auto 80px;}
    }
  }

  @include bp(tw){
    .insideAlt{width:90%;}
    .hlf1, .hlf2 {
      .textBox{margin-top:30px;}
    }
  }

  @include bp(tb){padding:30px 0 80px;
    .inside{width:90%;}
    .hlf1, .hlf2 {@include sec-default();text-align:center;
      .textBox{width:100%;margin:0 auto;}
      .fwOnly{display: none;}
    }
    .hlf1 {margin:0 0 20px;
      .reg, .mobileOnly{display: inline-block; height:250px; margin:10px 1% 0;}
    }
    h3{font-size:1.2rem;margin-bottom: 10px;}
    p{margin-top:15px;}
  }

  @media (max-width:850px) {
    .hlf1{
      .reg, .mobileOnly{height:200px;}
    }
  }

  @include bp(md) {padding:25px 0 40px;
    h3{margin-bottom: 5px;}
    .hlf1, .hlf2{
      p{font-size: 17px; margin-bottom: 15px;}
    }
    .hlf1 {margin-bottom:10px;
      p{margin-top:0;}
    }
  }

  // @include bp(mb) {
  //   .hlf1{
  //     .reg{display: none;} 
  //     .mobileOnly{height:auto;}
  //   }
  // }
 
  @include bp(oc) {
    .inside{width: 92%;}
    .hlf1 {margin-bottom:10px;
      p{margin-bottom:10px;}
      .reg, .mobileOnly{height:auto; margin:10px 0 0;}
      .reg{width:42%; float:left;}
      .mobileOnly{width:55%; float:right;}
    }
  }

  @include bp(sm) {padding:20px 0 35px;
  }
}

.HomeWrap{@include sec-default();box-shadow:0px 10px 30px rgba($black,.1);background-color:$lcream;}
.cfFooter{width:100%; position: absolute; bottom:5px; z-index: 1;left:0;
  .ftLogo, .fbPlug {display: inline-block;}
  .ftLogo{width:200px; @include center-block();margin-bottom:25px;}
  .copyHs p{font-size:14px;text-align:center; margin:2px 0;}
  
  @include bp(tb) {
    .ftLogo{width:180px;}
  }
  @include bp(md) {
    .ftLogo{width:150px; margin-bottom:15px;}
  }
}